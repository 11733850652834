import React from "react";
import PropTypes from "prop-types";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import config from "../../../../../../config";

import FileUpload from "../../../../../../components/FileUpload";
import Price from "../../../../../../components/Price";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  reject: {
    color: "white",
    backgroundColor: red[400],
    "&:hover": {
      backgroundColor: red[500],
    },
  },
  accept: {
    color: "white",
    backgroundColor: green[400],
    "&:hover": {
      backgroundColor: green[500],
    },
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const VersionActions = ({
  onReject,
  onValid,
  id,
  remainingFreeVersion,
  pricePerUpdate,
  showRejectButton,
  showAcceptButton,
}) => {
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [comment, setComment] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [acceptDialog, setAcceptDialog] = React.useState(false);
  const [rejectDialog, setRejectDialog] = React.useState(false);

  const handleOpenRejectDialog = () => {
    setRejectDialog(true);
    setComment("");
    setFiles([]);
    setLoading(false);
  };

  const handleCloseRejectDialog = () => {
    setRejectDialog(false);
    setLoading(false);
  };

  const handleOpenAcceptDialog = () => {
    setAcceptDialog(true);
    setLoading(false);
  };

  const handleCloseAcceptDialog = () => {
    setAcceptDialog(false);
    setLoading(false);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleStopLoading = () => {
    setLoading(false);
  };

  const handleSubmitReject = (e) => {
    e.preventDefault();
    setLoading(true);
    onReject(id, comment, files)
      .then(handleCloseRejectDialog)
      .catch(handleStopLoading);
  };

  const handleSubmitAccept = () => {
    setLoading(true);
    onValid(id).then(handleCloseAcceptDialog).catch(handleStopLoading);
  };

  return (
    <React.Fragment>
      <AccordionActions>
        {showRejectButton && (
          <Button
            classes={{ root: classes.reject }}
            variant="contained"
            onClick={handleOpenRejectDialog}
          >
            Refuser
          </Button>
        )}
        {showAcceptButton && (
          <Button
            classes={{ root: classes.accept }}
            variant="contained"
            color="primary"
            onClick={handleOpenAcceptDialog}
          >
            Valider
          </Button>
        )}
      </AccordionActions>
      <Dialog onClose={handleCloseRejectDialog} open={rejectDialog}>
        {loading && <LinearProgress />}
        <DialogTitle>Refuser cette version</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Êtes-vous sur ce vouloir refuser cette version ?</strong>
            <br />
            <br />
            Merci d&apos;ajoutez un commentaire expliquant votre refus. Vous
            pouvez également ajouter des fichiers ci-dessous en plus du
            commentaire.
          </DialogContentText>

          <form onSubmit={handleSubmitReject} id={`form-reject-${id}`}>
            <TextField
              className={classes.input}
              disabled={loading}
              label="Commentaire"
              multiline
              rows={5}
              variant="outlined"
              value={comment}
              onChange={handleChangeComment}
              required
              fullWidth
            />
          </form>
          <Box marginBottom={2}>
            <FileUpload
              files={files}
              onChange={setFiles}
              loading={loading}
              multiple
              maxSizePerFileMb={config.maxFileSizeMb}
            />
          </Box>
          {!remainingFreeVersion && (
            <Alert severity="warning" className={classes.alert}>
              Si vous refusez cette version, la nouvelle version vous sera
              facturée <Price value={pricePerUpdate} />.<br />
              Cependant si il sagît d&apos;une erreur de notre part, la nouvelle
              version ne vous sera pas facturée.
            </Alert>
          )}
          {remainingFreeVersion > 0 && (
            <Alert severity="success" className={classes.alert}>
              Vous pouvez refuser cette version gratuitement. <br />
              Il vous reste encore {remainingFreeVersion} version(s) gratuite(s)
              dans votre forfait.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleCloseRejectDialog}
            autoFocus
          >
            Annuler
          </Button>
          <Button
            disabled={loading}
            type="submit"
            form={`form-reject-${id}`}
            color="primary"
            variant="contained"
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <SaveIcon />
              )
            }
          >
            Refuser
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleCloseAcceptDialog} open={acceptDialog}>
        {loading && <LinearProgress />}
        <DialogTitle>Accepter cette version</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <strong>Êtes-vous sur ce vouloir accepter cette version ?</strong>
            <br />
          </DialogContentText>
          <Alert severity="info" className={classes.alert}>
            Vous pourrez toujours refuser plus tard tant que votre devis
            n&apos;est pas prêt.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleCloseAcceptDialog}
            autoFocus
          >
            Annuler
          </Button>
          <Button
            disabled={loading}
            onClick={handleSubmitAccept}
            color="primary"
            variant="contained"
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <SaveIcon />
              )
            }
          >
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

VersionActions.defaultProps = {
  remainingFreeVersion: 0,
  pricePerUpdate: 0,
  showRejectButton: true,
  showAcceptButton: true,
};

VersionActions.propTypes = {
  onReject: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  remainingFreeVersion: PropTypes.number,
  pricePerUpdate: PropTypes.number,
  id: PropTypes.number.isRequired,
  showRejectButton: PropTypes.bool,
  showAcceptButton: PropTypes.bool,
};

export default VersionActions;
