import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Price from "../../../../components/Price";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: theme.spacing(1),
    fontWeight: 500,
  },
  value: {
    backgroundColor: theme.palette.background.default,
    padding: "0px 7px",
    borderRadius: 3,
  },
}));

const BasicINfo = ({ price, value, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography color="textSecondary" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.value}>
        <Typography>
          {!price && value}
          {price && price > 0 && <Price value={value} />}
        </Typography>
      </div>
    </div>
  );
};

BasicINfo.propTypes = {
  price: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default BasicINfo;
