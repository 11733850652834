import React from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import InfoIcon from "@material-ui/icons/Info";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

import MoreBatInfo from "./components/MoreBatInfo";
import BasicInfo from "./components/BasicInfo";
import Version from "./components/Version";
import BatStatus from "../../components/BatStatus";
import config from "../../config";

const styles = (theme) => ({
  card: {
    marginBottom: 20,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(3)}px 0`,
  },
  commercial: {
    fontWeight: 600,
  },
  email: {
    color: theme.palette.text.primary,
  },
});

class Bat extends React.Component {
  state = {
    infoDialog: false,
    versionIndex: null,
  };

  componentDidMount() {
    this.setState({
      versionIndex: this.props.versions.length - 1,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bat.id_bat !== this.props.bat.id_bat) {
      this.setState({
        versionIndex: prevProps.versions.length - 1,
      });
    }
  }

  handleCloseInfoDialog = () => {
    this.setState({ infoDialog: false });
  };

  handleOpenInfoDialog = () => {
    this.setState({ infoDialog: true });
  };

  handleChangeVersionIndex = (index) => (event, isExpanded) => {
    this.setState({ versionIndex: isExpanded ? index : null });
  };

  handleReject = (id_bat_version, comment, files) => {
    return this.props.updateBatVersion(
      id_bat_version,
      { status: config.batStatus.rejected, comment },
      files
    );
  };

  handleAccept = (id_bat_version) => {
    return this.props.updateBatVersion(id_bat_version, {
      status: config.batStatus.accepted,
    });
  };

  handleUpdate = (id_bat_version, comment) => {
    return this.props.updateBatVersion(id_bat_version, { comment });
  };

  renderVersions = () => {
    console.log(this.props);
    const { versionIndex } = this.state;
    const {
      bat,
      versions,
      token,
      deleteBatDocument,
      createBatDocuments,
    } = this.props;

    return versions.map((version, i) => (
      <Version
        key={version.id_bat_version}
        index={i}
        id_bat={bat.id_bat}
        token={token}
        canEdit={
          bat.quote.status.name === "WAITING" && versions.length - 1 === i
        }
        expanded={i === versionIndex}
        version={version}
        onChange={this.handleChangeVersionIndex}
        remainingFreeVersion={
          bat.nb_free_update - bat.nb_versions - bat.nb_free_version > 0
            ? bat.nb_free_update - bat.nb_versions - bat.nb_free_version
            : 0
        }
        pricePerUpdate={bat.price_per_update}
        onReject={this.handleReject}
        onValid={this.handleAccept}
        onUpdate={this.handleUpdate}
        onDeleteDocument={deleteBatDocument}
        onAddDocuments={createBatDocuments}
      />
    ));
  };

  render() {
    const { classes, bat, client } = this.props;
    const { infoDialog } = this.state;

    return (
      <React.Fragment>
        <div className={classes.top}>
          <Container maxWidth="md">
            {client && (
              <Card raised className={classes.card}>
                <CardHeader
                  title={`Bonjour, ${client.infos.contact_name}`}
                  subheader={client.name}
                />
              </Card>
            )}
            <MoreBatInfo bats={bat.other_bats_of_quote} />
            <Typography variant="h5" gutterBottom>
              {bat.name}
            </Typography>
            <Box marginBottom={3}>
              <BasicInfo
                label="Statut"
                value={
                  <BatStatus className={classes.status} value={bat.status} />
                }
              />
            </Box>
            {bat.quote.status.name !== "WAITING" && (
              <Box marginBottom={2}>
                <Alert severity="info">
                  Le devis lié a ce BAT a déjà été accepté. Vous ne pouvez donc
                  plus demander de modification pour ce BAT.
                </Alert>
              </Box>
            )}
          </Container>
        </div>

        <Container maxWidth="md">
          <Box marginTop={3} marginBottom={3}>
            {this.renderVersions()}
          </Box>
        </Container>

        <Container maxWidth="md">
          <Box marginTop={6} marginBottom={5}>
            <Typography variant="h6" gutterBottom>
              Informations tarifaire du BAT
            </Typography>
            <BasicInfo label="Prix initial :" value={bat.initial_price} price />
            <BasicInfo
              label="Prix par version :"
              value={bat.price_per_update}
              price
            />
            <BasicInfo
              label="Version gratuite inclus (Forfait) :"
              value={bat.nb_free_update}
            />
            <BasicInfo label="Nombre de versions :" value={bat.nb_versions} />
            <BasicInfo
              label="Versions supplémentaires (facturé) :"
              value={bat.nb_paid_version}
            />
            <BasicInfo
              label="Versions de correction (non facturés) :"
              value={bat.nb_free_version}
            />
            <BasicInfo label="TOTAL :" value={bat.price} price />

            <Box marginTop={2}>
              <Button
                startIcon={<InfoIcon />}
                onClick={this.handleOpenInfoDialog}
                color="secondary"
                variant="contained"
                size="small"
              >
                Plus d&apos;information
              </Button>
            </Box>
          </Box>
        </Container>

        <Dialog onClose={this.handleCloseInfoDialog} open={infoDialog}>
          <DialogTitle>Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Si vous refusez une version suite à une erreur de notre part, la
              prochaine version (de correction) ne vous sera pas facturée.
              <br />
              <br />
              En revanche, si suite à un refus qui n&apos;est pas de notre fait,
              vous dépassez le nombre de versions gratuites du forfait, chaque
              nouvelle version vous sera facturée le prix par version.
              <br />
              <br />
              Le prix total peut alors changer si vous dépassez le nombre de
              versions gratuites du forfait.
              <br />
              <br />
              Si vous constatez une erreur de prix, ou dans le nombre de
              versions,
              <br />
              Merci de contacter directement le commercial en charge de votre
              devis : <br />
              <span className={classes.commercial}>
                {`${bat.quote.user_in_charge.name} : `}
              </span>
              <a
                className={classes.email}
                href={`mailto:${bat.quote.user_in_charge.email}`}
              >
                {bat.quote.user_in_charge.email}
              </a>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseInfoDialog} autoFocus>
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

Bat.propTypes = {
  bat: PropTypes.object.isRequired,
  versions: PropTypes.array,
  token: PropTypes.string.isRequired,
  client: PropTypes.object,
  updateBatVersion: PropTypes.func.isRequired,
  deleteBatDocument: PropTypes.func.isRequired,
  createBatDocuments: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    card: PropTypes.string,
    top: PropTypes.string,
    status: PropTypes.string,
    commercial: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default withStyles(styles)(Bat);
