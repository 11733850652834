import React from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import BatStatus from "../../../../components/BatStatus";
import FileUpload from "../../../../components/FileUpload";
import BatImage from "../BatImage";
import BatFile from "../BatFile";
import config from "../../../../config";

import VersionActions from "./components/VersionActions";
import "./Version.css";

SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles((theme) => ({
  summary: {
    alignItems: "center",
    display: "flex",
  },
  status: {
    marginLeft: theme.spacing(2),
  },
  fix: {
    fontWeight: 600,
    marginLeft: "auto",
    marginRight: theme.spacing(2),
  },
  inner: {
    width: "100%",
  },
  file: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    whiteSpace: "pre-wrap",
  },
}));

const Version = ({
  version,
  index,
  id_bat,
  token,
  expanded,
  onChange,
  onReject,
  onValid,
  onAddDocuments,
  onDeleteDocument,
  onUpdate,
  canEdit,
  remainingFreeVersion,
  pricePerUpdate,
}) => {
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [comment, setComment] = React.useState(version.comment);
  const [loadingComment, setLoadingComment] = React.useState(false);
  const [loadingFiles, setLoadingFiles] = React.useState(false);

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const renderFiles = () => {
    if (version.bat_documents.length === 0) {
      return <Typography color="textSecondary">Aucuns fichier</Typography>;
    }
    return (
      <Swiper
        navigation={version.bat_documents.length > 1}
        pagination={
          version.bat_documents.length > 1
            ? {
                clickable: true,
              }
            : false
        }
      >
        {version.bat_documents.map((document) => (
          <SwiperSlide key={document.id_bat_document}>
            <BatImage
              id_bat={id_bat}
              filename={document.filename}
              token={token}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  const handleDeleteDocument = (id_bat_document) => {
    return onDeleteDocument(id_bat_document);
  };

  const handleStopLoadingFiles = () => {
    setLoadingFiles(false);
    setFiles([]);
  };

  const handleChangeFiles = (files) => {
    if (!files || files.length === 0) {
      return;
    }

    setLoadingFiles(true);
    setFiles(files);
    return onAddDocuments(version.id_bat_version, files).then(
      handleStopLoadingFiles,
      handleStopLoadingFiles
    );
  };

  const renderFilesOfClient = (deletable = false) => {
    if (version.bat_documents_of_client.length === 0) {
      return <Typography color="textSecondary">Aucuns fichier</Typography>;
    }

    return version.bat_documents_of_client.map((document) => (
      <BatFile
        key={document.id_bat_document}
        className={classes.file}
        id_bat={id_bat}
        id_bat_document={document.id_bat_document}
        filename={document.filename}
        token={token}
        onDelete={deletable ? handleDeleteDocument : undefined}
      />
    ));
  };

  const handleStopLoadingComment = () => {
    setLoadingComment(false);
  };

  const handleSaveComment = () => {
    setLoadingComment(true);
    onUpdate(version.id_bat_version, comment).then(
      handleStopLoadingComment,
      handleStopLoadingComment
    );
  };

  const renderVersionClientComment = () => {
    if (version.status === config.batStatus.accepted) return;

    if (comment) {
      return (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
            Votre commentaire
          </Typography>
          {canEdit ? (
            <React.Fragment>
              <TextField
                className={classes.input}
                label="Commentaire"
                multiline
                rows={4}
                variant="outlined"
                value={comment}
                onChange={handleChangeComment}
                fullWidth
                disabled={loadingComment}
              />
              <Box marginTop={1}>
                <Button
                  disabled={loadingComment}
                  endIcon={
                    loadingComment ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleSaveComment}
                >
                  Enregistrer
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <Typography color="textSecondary">{version.comment}</Typography>
          )}
        </React.Fragment>
      );
    }

    return (
      <Alert severity="info">
        Le BAT vous convient ? Vous pouvez le valider. Sinon, cliquez sur
        refuser et décrivez-nous vos modifications
      </Alert>
    );
  };

  return (
    <React.Fragment>
      <Accordion expanded={expanded} onChange={onChange(index)}>
        <AccordionSummary
          classes={{ content: classes.summary }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <Typography>{version.name}</Typography>
          <BatStatus className={classes.status} value={version.status} />
          {version.free && (
            <Typography className={classes.fix}>Correction</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.inner}>
            {version.free && (
              <Box marginBottom={2}>
                <Alert severity="info">
                  Cette version étant une correction elle ne vous sera pas
                  facturé
                </Alert>
              </Box>
            )}
            <Box marginBottom={2}>
              <Typography variant="h6" gutterBottom>
                Fichiers
              </Typography>
              {renderFiles()}
            </Box>
            <br />
            <br />
            <Box marginBottom={5}>
              <Typography variant="h6" gutterBottom>
                Commentaire accompagnant le BAT
              </Typography>
              <Typography color="textSecondary" className={classes.paragraph}>
                {version.description}
              </Typography>
            </Box>
            <Box marginBottom={2}>{renderVersionClientComment()}</Box>
            {version.bat_documents_of_client.length > 0 && (
              <Box marginBottom={1}>
                <Typography variant="h6" gutterBottom>
                  Vos fichiers
                </Typography>
                {renderFilesOfClient(
                  canEdit && version.status === config.batStatus.rejected
                )}
              </Box>
            )}
            {canEdit && version.status === config.batStatus.rejected && (
              <FileUpload
                files={files}
                onChange={handleChangeFiles}
                loading={loadingFiles}
                multiple
                maxSizePerFileMb={config.maxFileSizeMb}
              />
            )}
          </Box>
        </AccordionDetails>
        {canEdit && (
          <React.Fragment>
            <Divider />
            <VersionActions
              id={version.id_bat_version}
              showRejectButton={version.status !== config.batStatus.rejected}
              showAcceptButton={version.status !== config.batStatus.accepted}
              onReject={onReject}
              onValid={onValid}
              remainingFreeVersion={remainingFreeVersion}
              pricePerUpdate={pricePerUpdate}
            />
          </React.Fragment>
        )}
      </Accordion>
    </React.Fragment>
  );
};

Version.defaultProps = {
  expanded: false,
  remainingFreeVersion: 0,
  pricePerUpdate: 0,
  canEdit: false,
};

Version.propTypes = {
  version: PropTypes.object,
  expanded: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  onAddDocuments: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  id_bat: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
  remainingFreeVersion: PropTypes.number,
  pricePerUpdate: PropTypes.number,
  canEdit: PropTypes.bool,
};

export default Version;
