import { store } from "react-notifications-component";

const addMessage = (type, message) => {
  store.addNotification({
    message,
    type,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "flipInX"],
    animationOut: ["animated", "flipOutX"],
    dismiss: {
      duration: 3000,
    },
  });
};

const Alert = {
  success: (msg) => {
    addMessage("success", msg);
  },
  error: (msg) => {
    addMessage("danger", msg);
  },
  info: (msg) => {
    addMessage("info", msg);
  },
  warning: (msg) => {
    addMessage("warning", msg);
  },
  default: (msg) => {
    addMessage("default", msg);
  },
};

export default Alert;
