import React from "react";
import PropTypes from "prop-types";

const Price = ({ value, precision }) => (
  <span>{value.toFixed(precision)}&nbsp;€</span>
);

Price.defaultProps = {
  precision: 2,
};

Price.propTypes = {
  precision: PropTypes.number,
  value: PropTypes.number.isRequired,
};

export default Price;
