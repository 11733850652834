import React from "react";
import queryString from "query-string";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import {
  getBat,
  updateBatVesion,
  deleteBatDocument,
  createBatDocument,
} from "../../api";

import Bat from "./Bat";
import PropTypes from "prop-types";

const styles = (theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
});

class BatQuery extends React.Component {
  state = {
    bat: null,
    loading: true,
    token: null,
    client: null,
  };

  componentDidMount() {
    this.fetchBat();
  }

  componentDidUpdate(prevProps, prevState) {
    const { id_bat } = this.props.match.params;

    if (prevState.bat) {
      if (id_bat !== prevProps.match.params.id_bat) {
        this.fetchBat();
      }
    }
  }

  fetchBat = (loading = true) => {
    const { id_bat } = this.props.match.params;
    const queryParams = queryString.parse(this.props.location.search);

    if (!id_bat || !queryParams.token) {
      this.setState({ loading: false });
    } else {
      if (loading) {
        this.setState({ loading: true });
      }

      getBat(id_bat, queryParams.token)
        .then((bat) => {
          this.setState({
            bat: bat,
            versions: bat.bat_versions,
            loading: false,
            token: queryParams.token,
            client: bat.quote.client,
          });
        })
        .catch((err) => {
          this.setState({
            bat: null,
            versions: [],
            loading: false,
            token: null,
            client: null,
          });
        });
    }
  };

  updateBatVersion = (id_bat_version, params, files) => {
    const queryParams = queryString.parse(this.props.location.search);
    return updateBatVesion(
      this.state.bat.id_bat,
      id_bat_version,
      params,
      files,
      queryParams.token
    ).then((res) => {
      this.fetchBat(false);
      return res;
    });
  };

  deleteBatDocument = (id_bat_document) => {
    const queryParams = queryString.parse(this.props.location.search);
    return deleteBatDocument(
      this.state.bat.id_bat,
      id_bat_document,
      queryParams.token
    ).then((res) => {
      this.fetchBat(false);
      return res;
    });
  };

  createBatDocuments = (id_bat_version, files) => {
    const queryParams = queryString.parse(this.props.location.search);
    return createBatDocument(
      this.state.bat.id_bat,
      id_bat_version,
      files,
      queryParams.token
    ).then((res) => {
      this.fetchBat(false);
      return res;
    });
  };

  render() {
    const { classes } = this.props;
    const { bat, versions, loading, token, client } = this.state;

    if (loading) {
      return (
        <div className={classes.center}>
          <CircularProgress color="secondary" />
        </div>
      );
    }

    if (!bat) {
      return (
        <div className={classes.center}>
          <Typography variant="h6">
            Impossible de charger cette page.
          </Typography>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Bat
          bat={bat}
          versions={versions}
          token={token}
          client={client}
          updateBatVersion={this.updateBatVersion}
          deleteBatDocument={this.deleteBatDocument}
          createBatDocuments={this.createBatDocuments}
        />
      </React.Fragment>
    );
  }
}

BatQuery.propTypes = {
  classes: PropTypes.shape({
    center: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id_bat: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.object,
  }),
};

export default withStyles(styles)(BatQuery);
