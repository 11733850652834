import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
}));

const Error404 = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6">Page introuvable (404)</Typography>
    </div>
  );
};

export default Error404;
