import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
}));

const Footer = ({ className }) => {
  const classes = useStyles();
  return (
    <footer className={cn(classes.container, className)}>
      <Typography variant="h6" align="center" gutterBottom>
        Monsieur TSHIRT
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        {config.contact.email}
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p"
      >
        {config.contact.phone}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href={config.siteUrl}>
          Monsieur TSHIRT
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
