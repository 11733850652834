const config = {
  cdnUrl: process.env.REACT_APP_CDN_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  siteUrl: process.env.REACT_APP_SITE_URL,
  contact: {
    phone: "+33 9 77 55 25 75",
    email: "commercial@monsieurtshirt.com",
  },
  batStatus: {
    waiting: "WAITING",
    accepted: "ACCEPTED",
    rejected: "REJECTED",
    inProgress: "IN_PROGRESS",
    waitingInitialVersion: "WAITING_INITIAL_VERSION",
  },
  maxFileSizeMb: 50,
};

export default config;
