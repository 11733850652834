import config from "../config";
import Alert from "../utils/Alert";

const call = ({
  url,
  method = "GET",
  params = null,
  displayAlertOnSuccess = true,
  useFormData = false,
  files = null,
}) => {
  let formData = null;
  if (useFormData) {
    formData = new FormData();
    if (files) {
      Array.prototype.forEach.call(files, (f) => formData.append("files", f));
    }
    formData.append("body", JSON.stringify(params));
  }

  return new Promise((resolve, reject) => {
    fetch(`${config.apiUrl}${url}`, {
      method,
      headers: {
        //'Content-Type': !useFormData ? 'application/json' : 'multipart/form-data',
        ...(!useFormData ? { "Content-Type": "application/json" } : {}),
      },
      ...(params || useFormData
        ? { body: formData ? formData : JSON.stringify(params) }
        : {}),
    })
      .then((res) => res.json())
      .then((res) => {
        if (
          res &&
          res.msg &&
          (res.status !== "success" || displayAlertOnSuccess)
        ) {
          Alert[res.status](res.msg);
        }
        if (res.status && res.status === "success") {
          resolve(res.data);
        } else {
          reject(res.msg || "Error");
        }
      })
      .catch((err) => {
        Alert.error("Erreur réseaux");
        console.error(err);
        reject(err);
      });
  });
};

export const getBat = (id_bat, token) => {
  return call({
    url: `/bat/${id_bat}?token=${token}`,
    displayAlertOnSuccess: false,
  });
};

export const updateBatVesion = (
  id_bat,
  id_bat_version,
  params,
  files,
  token
) => {
  return call({
    url: `/bat/${id_bat}/version/${id_bat_version}?token=${token}`,
    method: "PUT",
    params,
    files,
    useFormData: true,
  });
};

export const deleteBatDocument = (id_bat, id_bat_document, token) => {
  return call({
    url: `/bat/${id_bat}/document/${id_bat_document}?token=${token}`,
    method: "DELETE",
  });
};

export const createBatDocument = (id_bat, id_bat_version, files, token) => {
  return call({
    url: `/bat/${id_bat}/version/${id_bat_version}/document?token=${token}`,
    method: "POST",
    files,
    useFormData: true,
  });
};
