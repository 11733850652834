import React from "react";
import ReactNotification from "react-notifications-component";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import blue from "@material-ui/core/colors/blue";

import { BrowserRouter as Router, Switch } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";

import BatPage from "./pages/Bat";
import HomePage from "./pages/Home";
import Error404 from "./pages/Error404";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? "dark" : "light",
          primary: blue,
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <ReactNotification />
      <CssBaseline />
      <Router>
        <Switch>
          <DefaultLayout exact path="/bat/:id_bat" component={BatPage} />
          <DefaultLayout exact path="/" component={HomePage} />
          <DefaultLayout path="*" component={Error404} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
