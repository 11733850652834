import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "../Link";
import config from "../../config";

import styles from "./header.module.css";

const Header = () => (
  <AppBar position="fixed">
    <Toolbar className={styles.container}>
      <Link to="/">
        <img
          width={100}
          src={`${config.cdnUrl}/b2b/logo-monsieur-tshirt-white.png`}
          alt="Logo Monsieur TSHIRT"
        />
      </Link>
    </Toolbar>
  </AppBar>
);

export default Header;
