import React, { useState } from "react";
import PropTypes from "prop-types";
import config from "../../../../config";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  button: {
    position: "absolute",
    bottom: 30,
    right: 30,
    backgroundColor: theme.palette.primary[500],
    "&:hover": {
      backgroundColor: theme.palette.primary[700],
    },
  },
}));

const BatFile = ({ id_bat, filename, token }) => {
  const classes = useStyles();
  const [zoomDialog, setZoomDialog] = useState(false);

  const startDownload = () => {
    const a = document.createElement("a");
    a.href = `${config.apiUrl}/bat/${id_bat}/document/${filename}?token=${token}`;
    a.download = filename || "download";
    a.click();
  };

  return (
    <>
      <Box marginBottom={2} className={classes.container}>
        <img
          src={`${config.apiUrl}/bat/${id_bat}/document/${filename}?token=${token}`}
          alt={filename}
          style={{ maxWidth: "100%", cursor: "pointer" }}
          onClick={() => setZoomDialog(true)}
        />
        <IconButton onClick={startDownload} className={classes.button}>
          <GetAppIcon />
        </IconButton>
      </Box>
      <Dialog
        open={zoomDialog}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => setZoomDialog(false)}
      >
        <DialogContent>
          <img
            src={`${config.apiUrl}/bat/${id_bat}/document/${filename}?token=${token}`}
            alt={filename}
            style={{ maxWidth: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

BatFile.propTypes = {
  id_bat: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default BatFile;
