import React from "react";
import Typography from "@material-ui/core/Typography";
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Chip from "@material-ui/core/Chip";
import { darken, lighten } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PropTypes from "prop-types";
import attrAccept from "attr-accept";

const useStyles = makeStyles((theme) => {
  const getColor = theme.palette.type === "light" ? darken : lighten;
  const getBackgroundColor = theme.palette.type === "light" ? lighten : darken;

  return {
    dropzone: {
      border: `2px dashed ${theme.palette.action.active}`,
      padding: theme.spacing(3),
      width: "100%",
      color: theme.palette.action.active,
      position: "relative",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "border-color .2s, color .2s",
    },
    over: {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },
    disabled: {
      borderColor: theme.palette.action.disabled,
      color: theme.palette.action.disabled,
    },
    input: {
      position: "absolute",
      opacity: 0,
      height: "100%",
      cursor: "inherit",
      width: "100%",
    },
    icon: {
      position: "absolute",
      left: "2px",
      top: "6px",
    },
    files: {
      marginTop: theme.spacing(1),
    },
    file: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    error: {
      color: getColor(theme.palette.error.main, 0.6),
      backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      "&:focus": {
        backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
      },
    },
    iconError: {
      color: theme.palette.error.main,
    },
    info: {
      fontStyle: "italic",
    },
    max: {
      position: "absolute",
      bottom: "1px",
      right: "6px",
      fontSize: "1em",
      fontStyle: "italic",
    },
  };
});

const FileUpload = ({
  onChange,
  files,
  className,
  placeholder,
  loading,
  accept,
  multiple,
  required,
  disabled,
  mergeNewFiles,
  maxSizePerFileMb,
  maxFiles,
  displayFiles,
}) => {
  const classes = useStyles();
  const [over, setOver] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const handleChangeFiles = (filesInput) => {
    const newFiles =
      mergeNewFiles && multiple
        ? [...files, ...Array.from(filesInput)]
        : multiple
        ? Array.from(filesInput)
        : Array.from(filesInput)[0];

    const newErrors = [];

    if (maxSizePerFileMb !== undefined) {
      let validFilesCount = 0;
      for (let i = 0; i < newFiles.length; i++) {
        const size = newFiles[i].size / 1000 / 1000;
        if (size > maxSizePerFileMb) {
          newErrors.push(
            <span>
              {newFiles[i].name} |{" "}
              <strong>
                trop lourd {Math.round(size)}/{maxSizePerFileMb} Mo
              </strong>
            </span>
          );
          newFiles.splice(i, 1);
          i--;
          continue;
        }

        if (accept) {
          // check accept props and valid files
          const acceptable = attrAccept(
            {
              name: newFiles[i].name,
              type: newFiles[i].type,
            },
            accept
          );

          if (!acceptable) {
            newErrors.push(
              <span>
                {newFiles[i].name} | <strong>type non acceptable</strong>
              </span>
            );
            newFiles.splice(i, 1);
            i--;
            continue;
          }
        }

        if (maxFiles && validFilesCount + 1 > maxFiles) {
          newErrors.push(
            <span>
              {newFiles[i].name} |{" "}
              <strong>
                quantité max {validFilesCount + 1}/{maxFiles}
              </strong>
            </span>
          );
          newFiles.splice(i, 1);
          i--;
          continue;
        }
        validFilesCount++;
      }
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
    }
    onChange(newFiles);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleChangeFiles(e.dataTransfer.files);
    setOver(false);
  };

  const handleDragLeave = () => {
    setOver(false);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!over) {
      setOver(true);
    }
    return false;
  };

  const handleInputDropZone = (e) => {
    e.preventDefault();
    if (e.target.files) {
      handleChangeFiles(e.target.files);
    }
  };

  const handleRemoveFile = (index) => {
    files.splice(index, 1);
    onChange([...files]);
  };

  const renderFiles = () =>
    files.map((file, i) => (
      <Chip
        disabled={disabled || loading}
        key={`file-${i}`}
        className={classes.file}
        onDelete={() => {
          handleRemoveFile(i);
        }}
        label={file.name}
      />
    ));

  const handleRemoveError = (index) => {
    errors.splice(index, 1);
    setErrors([...errors]);
  };

  const renderErrors = () =>
    errors.map((error, i) => (
      <Chip
        clickable={false}
        key={`error-${i}`}
        className={classes.error}
        label={error}
        icon={<ErrorOutlineIcon className={classes.iconError} />}
        onDelete={() => {
          handleRemoveError(i);
        }}
      />
    ));

  return (
    <div className={className}>
      <div
        className={cn(classes.dropzone, {
          [classes.over]: over,
          [classes.disabled]: disabled || loading,
        })}
        onDrop={!disabled && !loading && handleDrop}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
      >
        <AttachFileIcon className={classes.icon} />
        {multiple && maxFiles && (
          <Typography className={classes.max} color="textSecondary">
            {files.length}/{maxFiles} fichiers max
          </Typography>
        )}
        {!loading && (
          <Typography color="inherit" className={classes.placeholder}>
            {placeholder && placeholder}
            {!placeholder &&
              multiple &&
              `Cliquez ou déplacez des fichiers dans la zone pour les ajouter.`}
            {!placeholder &&
              !multiple &&
              `Cliquez ou déplacez un fichier dans la zone pour l'ajouter.`}
          </Typography>
        )}
        {loading && <CircularProgress color="inherit" />}
        <input
          type="file"
          className={classes.input}
          onChange={handleInputDropZone}
          accept={accept}
          multiple={multiple}
          required={required}
          disabled={disabled}
        />
      </div>
      {maxSizePerFileMb && (
        <Typography className={classes.info} color="textSecondary">
          Max {maxSizePerFileMb} Mo par fichier
        </Typography>
      )}
      {displayFiles && <div className={classes.files}>{renderFiles()}</div>}
      {errors.length > 0 && (
        <div className={classes.files}>{renderErrors()}</div>
      )}
    </div>
  );
};

FileUpload.defaultProps = {
  required: false,
  multiple: false,
  disabled: false,
  loading: false,
  mergeNewFiles: true,
  displayFiles: true,
};

FileUpload.propTypes = {
  className: PropTypes.string,
  files: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  mergeNewFiles: PropTypes.bool,
  displayFiles: PropTypes.bool,
  maxSizePerFileMb: PropTypes.number,
  maxFiles: PropTypes.number,
};

export default FileUpload;
