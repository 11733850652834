import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    flexDirection: "column",
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom>
        Monsieur TSHIRT
      </Typography>
      <Typography align="center">
        Ce site est à destination des professionels.
        <br />
        Si vous cherchez notre site client merci de suivre ce lien:{" "}
        <Link color="inherit" href={config.siteUrl} title="Monsieur TSHIRT">
          monsieurtshirt.com
        </Link>
        <br />
        Si vous êtes un professionel et que vous cherchez à nous contacter merci
        d&apos;utiliser les informations en bas de page.
      </Typography>
    </div>
  );
};

export default Home;
