import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const MyLink = ({ children, ...props }) => (
  <Link component={RouterLink} {...props}>
    {children}
  </Link>
);

MyLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MyLink;
