import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import styles from "./defaultLayout.module.css";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
}));

const DefaultLayout = ({
  component: Component,
  additionalProps,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <Route
      {...otherProps}
      render={(routeProps) => (
        <div className={styles.container}>
          <Header />
          <div className={classes.offset} />
          <Component {...additionalProps} {...routeProps} />
          <Footer className={styles.footer} />
        </div>
      )}
    />
  );
};

DefaultLayout.defaultProps = {
  additionalProps: {},
};

DefaultLayout.propTypes = {
  component: PropTypes.elementType,
  additionalProps: PropTypes.object,
};

export default DefaultLayout;
