import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import cn from "classnames";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 3,
    display: "inline-block",
    fontWeight: "bold",
    padding: "5px 10px",
    textAlign: "center",
    textTransform: "uppercase",
  },
}));

const getStatusInfo = (status) => {
  switch (status) {
    case config.batStatus.waitingInitialVersion:
      return {
        name: "En attente 1er version",
        bgColor: grey[400],
        textColor: "black",
      };
    case config.batStatus.waiting:
      return { name: "En attente", bgColor: orange[400], textColor: "white" };
    case config.batStatus.inProgress:
      return { name: "En cours", bgColor: orange[400], textColor: "white" };
    case config.batStatus.accepted:
      return { name: "Accepté", bgColor: green[400], textColor: "white" };
    case config.batStatus.rejected:
      return { name: "Refusé", bgColor: red[400], textColor: "white" };
    default:
      return { name: "ERROR", bgColor: "#000", textColor: "white" };
  }
};

const BatStatus = ({ value, className }) => {
  const res = getStatusInfo(value);
  const classes = useStyles();

  return (
    <span
      className={cn(classes.container, className)}
      style={{ backgroundColor: res.bgColor, color: res.textColor }}
    >
      {res.name}
    </span>
  );
};

BatStatus.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default BatStatus;
