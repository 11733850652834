import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Link from "../../../../components/Link";
import Alert from "@material-ui/lab/Alert";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => ({}));

const MoreBatInfo = ({ className, bats }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const plural = bats && bats.length > 1;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const onClickLink = (e) => {
    handleClose();
  };

  const renderLinks = () =>
    bats.map((bat) => (
      <div key={bat.id_bat}>
        <Link
          to={`/bat/${bat.id_bat}?token=${bat.token}`}
          color="inherit"
          onClick={onClickLink}
        >
          {bat.name}
        </Link>
      </div>
    ));

  if (bats.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <Box marginBottom={2}>
        <Alert
          severity="info"
          action={
            <Button
              className={classes.button}
              color="inherit"
              size="small"
              onClick={handleClickOpen}
            >
              Voir
            </Button>
          }
        >
          {plural && `Vous avez d'autres BATs liés à votre devis`}
          {!plural && `Vous avez un autre BAT lié à votre devis`}
        </Alert>
      </Box>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>
          {plural && `Mes BATs`}
          {!plural && `Mon BAT`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {plural &&
              `Pour accéder aux autres BAT de votre devis, utilisez les liens ci-dessous`}
            {!plural &&
              `Pour accéder au second BAT de votre devis, utilisez le lien ci-dessous`}
          </DialogContentText>
          {renderLinks()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

MoreBatInfo.defaultProps = {
  bats: [],
};

MoreBatInfo.propTypes = {
  bats: PropTypes.array,
  className: PropTypes.string,
};

export default MoreBatInfo;
