import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../../../config";

const BatFile = ({
  onDelete,
  id_bat,
  id_bat_document,
  filename,
  token,
  className,
}) => {
  const [loading, setLoading] = React.useState();

  const startDownload = () => {
    const a = document.createElement("a");
    a.href = `${config.apiUrl}/bat/${id_bat}/document/${filename}?token=${token}`;
    a.download = filename || "download";
    a.click();
  };

  const handleRemoveLoading = () => {
    setLoading(false);
  };

  const handleDelete = () => {
    setLoading(true);
    onDelete(id_bat_document).then(handleRemoveLoading, handleRemoveLoading);
  };

  return (
    <Chip
      disabled={loading}
      className={className}
      size="small"
      onDelete={onDelete ? handleDelete : undefined}
      deleteIcon={
        loading && !!onDelete !== undefined ? (
          <CircularProgress color="inherit" size={15} />
        ) : undefined
      }
      onClick={startDownload}
      label={filename}
    />
  );
};

BatFile.propTypes = {
  id_bat: PropTypes.number.isRequired,
  id_bat_document: PropTypes.number.isRequired,
  filename: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  className: PropTypes.string,
};

export default BatFile;
